import gql from 'graphql-tag'

export const payWithCash = gql`
	mutation($id: ID!,$otp:Int!) {
		payWithCash(id: $id,otp:$otp) {
			id
		}
	}
`
export const verifyMobile = gql`
	mutation($id: ID!) {
		verifyMobile(rideId: $id) {
			id
		}
	}
`

export default {
	payWithCash,
	verifyMobile
}
