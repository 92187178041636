import React from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/SEO/seo";
import { PaymentComponent } from "../components/Payment";
import { useMediaQuery } from "react-responsive";

const Payment = ({ location }) => {
  const { state = {} } = location;
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });

  console.log("payment routes state params ", state);
  return (
    <Layout hideFooter hideHeader>
      <SEO title="Ride Payment" />
      <PaymentComponent data={state} />
    </Layout>
  );
};

export default Payment;
