import React, { useState, useContext, useEffect } from "react";
import { LanguageContext } from "../../../lang";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Row, Col } from "reactstrap";
import money from "./icons/money.png";
import ideal from "./icons/ideal.png";
import giropay from "./icons/giropay.svg";

import paypal from "./icons/paypal.png";
import credit from "./icons/credit.png";
import { defaultFormat } from "moment";
import { useMediaQuery } from "react-responsive";
import OtpInput from 'react-otp-input';
import { textAlign } from "@material-ui/system";

const Icon = (props) => (
  <img
    {...props}
    style={{ width: props.width || 40, height: props.height || 40 }}
  />
);

class Option extends React.Component {
  onClick = () => {
    console.log(this.props.value)
    this.props.onSelect(this.props.value);
  };

  render() {
    const {
      label,
      desc,
      value,
      selected,
      hide,
      style,
      children,
      icon,
      isDesktopOrLaptop,
      ...rest
    } = this.props;
    const Icon =
      selected === value ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon;
    if (hide) return null;

    return (
      <Card
        onClick={this.onClick}
        style={{
          margin: 20,
          padding: "10px 0 10px 0",
          ...style,
        }}
        {...rest}
        elevation={8}
      >
        <ListItem>
          <ListItemIcon>
            <Icon
              style={{
                color: selected === value ? "rgb(237 85 35)" : "#b1aeb4",
                fontSize: 20,
              }}
            />
          </ListItemIcon>
          {children || <ListItemText primary={label} secondary={desc} />}
          <ListItemIcon style={{ marginLeft: "8px" }}>{icon}</ListItemIcon>
        </ListItem>
      </Card>
    );
  }
}

export default ({ onPay, paymentAmount, discount, loading, verifyOTP, onPayC }) => {
  const [selected, setSelected] = useState();
  const [agree, setAgree] = useState();
  const [OTPPOP, setOTPPOP] = useState(true)
  const [OTP, setOTP] = useState("")
  const {
    translations: { payment },
  } = useContext(LanguageContext);
  const {
    discountText,
    termNote,
    termsAndConditions,
    payCash,
    cashPay,
    creditCard,
    paySecure,
    verify,
    phone,
    goback,
    resend,
    verifyPhone
  } = payment;
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });

  const onPayClick = () => {
    onPay(selected);
  };
  const onPayCash = () => {
    console.log(OTP)
    onPayC(parseInt(OTP));
    setOTP()
  };
  const onAgree = ({ target: { checked } }) => {
    setAgree(checked);
  };

  const toggleAgree = () => {
    setAgree(!agree);
  };
  const verifyOtpUser = () => {
    verifyOTP()

    setOTPPOP(false)
  }
  const resendOTP = () => {
    verifyOTP()
    alert("Otp Re-Sent Successfully")
  }
  const handleChange = (e) => {

    setOTP(e)

  }
  useEffect(() => {
    if (OTP) {
      if (OTP.length === 6) {
        onPayCash()
      }
    }

  }, [OTP]);
  return (
    <div>
      {OTPPOP ?
        <Card className="payment-card" elevation={isDesktopOrLaptop ? 2 : 0}>
            <>
              <Option
                value="cash"
                label={cashPay}
                desc={payCash}
                onSelect={setSelected}
                selected={selected}
                icon={<Icon src={money} alt="money" />}
                isDesktopOrLaptop={isDesktopOrLaptop}
              />
              {/* <div className="discount-text">
                {discountText.replace(" * ", discount)}
              </div> */}
            </>

          <Option
            value="giropay"
            label="Giropay"
            onSelect={setSelected}
            selected={selected}
            icon={<Icon src={giropay} alt="giropay" />}
            isDesktopOrLaptop={isDesktopOrLaptop}
          />
          <Option
            value="creditcard"
            label={creditCard}
            onSelect={setSelected}
            selected={selected}
            icon={<Icon src={credit} alt="credit" width={115} height={24} />}
            isDesktopOrLaptop={isDesktopOrLaptop}
          />
          <Option
            value="paypal"
            label="Paypal"
            onSelect={setSelected}
            selected={selected}
            icon={<Icon src={paypal} alt="paypal" width={30} />}
            isDesktopOrLaptop={isDesktopOrLaptop}
          />
          <div style={{ padding: 20 }}>
            <div style={{ cursor: "pointer" }}>
              <span>{termNote}</span>
              <a href="/general-conditions">{termsAndConditions}</a>
            </div>
            {
              selected != "cash" ? <div
                className={`ts-form-next-link pay-btn ${
                  selected || !loading ? "" : "disable"
                  }`}
                onClick={onPayClick}
                style={{ cursor: "pointer" }}
              >
                {loading ? "Please wait..." : paySecure}
              </div> : <div
                className={`ts-form-next-link pay-btn ${
                  selected || !loading ? "" : "disable"
                  }`}
                onClick={verifyOtpUser}//onPayCash
                style={{ cursor: "pointer" }}
              >
                  {loading ? "Please wait..." : verifyPhone}
                </div>
            }


          </div>
        </Card>
        :
        <div>
          {isDesktopOrLaptop ? <Card className="payment-card" style={{ padding: "0px", margin: "100px 100px" }} elevation={isDesktopOrLaptop ? 2 : 0}>
            <div style={{ textAlign: "center", marginBottom: "10px", padding: "70px", backgroundColor: "#06084D", width: "100%" }}>
              <h1 style={{ textAlign: "center", marginBottom: "10px", color: "#ED5523", fontWeight: "700" }}>
                {loading ? "Please wait..." : verifyPhone}
              </h1>
            </div>

            <OtpInput
              value={OTP}
              onChange={handleChange}
              numInputs={6}
              separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
              shouldAutoFocus={true}
              containerStyle={{ width: "100%", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "70px" }}
              inputStyle={{ fontSize: "1.7rem", width: "100%", border: "0px solid", borderBottom: "1px solid black" }}

            />
            <div style={{ display: "flex" }}>
              <div
                className={`ts-form-next-link pay-btn ${
                  selected || !loading ? "" : "disable"
                  }`}
                onClick={() => setOTPPOP(true)}
                style={{ cursor: "pointer", margin: "0 12px", marginBottom: "12px" }}
              >
                {loading ? "Please wait..." : goback}
              </div>
              <div
                className={`ts-form-next-link pay-btn ${
                  selected || !loading ? "" : "disable"
                  }`}
                onClick={resendOTP}
                style={{ cursor: "pointer", margin: "0 12px", marginBottom: "12px" }}
              >
                {loading ? "Please wait..." : resend}
              </div>
              {/* <div
              className={`ts-form-next-link pay-btn ${
                selected || !loading ? "" : "disable"
                }`}
              onClick={onPayCash}
              style={{ cursor: "pointer", margin: "0 12px", marginBottom: "12px" }}
            >
              {loading ? "Please wait..." : verifyPhone}
            </div> */}
            </div>

          </Card> : <div style={{ width: "100%", marginLeft: "15px" }}>

              <div style={{ textAlign: "center", marginBottom: "10px", padding: "70px", backgroundColor: "#06084D", width: "90%" }}>
                <h2 style={{ textAlign: "center", marginBottom: "10px", color: "#ED5523", fontWeight: "700" }}>
                  {loading ? "Please wait..." : verifyPhone}
                </h2>
              </div>

              <OtpInput
                value={OTP}
                onChange={handleChange}
                numInputs={6}
                separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                shouldAutoFocus={true}
                containerStyle={{ width: "90%", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "70px" }}
                inputStyle={{ fontSize: "1.7rem", width: "90%", border: "0px solid", borderBottom: "1px solid black", marginLeft: "7%" }}

              />
              <div style={{ display: "flex", width: "90%" }}>
                <div
                  className={`ts-form-next-link pay-btn ${
                    selected || !loading ? "" : "disable"
                    }`}
                  onClick={() => setOTPPOP(true)}
                  style={{ cursor: "pointer", margin: "0 12px", marginBottom: "12px" }}
                >
                  {loading ? "Please wait..." : goback}
                </div>
                <div
                  className={`ts-form-next-link pay-btn ${
                    selected || !loading ? "" : "disable"
                    }`}
                  onClick={resendOTP}
                  style={{ cursor: "pointer", margin: "0 12px", marginBottom: "12px" }}
                >
                  {loading ? "Please wait..." : resend}
                </div>
                {/* <div
              className={`ts-form-next-link pay-btn ${
                selected || !loading ? "" : "disable"
                }`}
              onClick={onPayCash}
              style={{ cursor: "pointer", margin: "0 12px", marginBottom: "12px" }}
            >
              {loading ? "Please wait..." : verifyPhone}
            </div> */}
              </div>

            </div>}

        </div>


      }
    </div >


  );
};
