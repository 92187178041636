import gql from "graphql-tag";

export const GET_SETTING = gql`
  query getSettings($data: String!) {
    setting(id: $data) {
      id
      timeP1
      timeP2
      timeP3
      nearDriverRadius
      onlinePaymentDiscount
    }
  }
`;

export const GET_FARE = gql`
  query($id: String!) {
    ride(id: $id) {
      netFare
      setting {
        onlinePaymentDiscount
      }
      status
    }
  }
`;

export default {
  GET_SETTING,
};
