import React, { useState, useContext } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import "./style.css";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useMediaQuery } from "react-responsive";
import { LanguageContext } from "../../lang";
import { GET_FARE } from "./graphql/queries";
import { payWithCash, verifyMobile } from "./graphql/mutations";
import { isBrowser } from "../../utils/general";
import { Row, Col } from "reactstrap";
import Methods from "./components/methods";

const PaymentComponent = () => {
  let rideId = isBrowser() && localStorage.getItem("XXXX");

  const {
    translations: { payment }
  } = useContext(LanguageContext);
  const { paymentHeading } = payment;
  const [_loading, setLoading] = useState(false);
  const [
    payWithCashTrigger,
    {
      data: payWithCashData,
      error: payWithCashError,
      loading: payWithCashLoading
    }
  ] = useMutation(payWithCash);
  const [
    verifyMobileTrigger,
    {
      data: verifyMobileTriggerData,
      error: verifyMobileTriggerError,
      loading: verifyMobileTriggerLoading
    }
  ] = useMutation(verifyMobile);

  const { data, loading, error } = useQuery(GET_FARE, {
    variables: {
      id: rideId
    }
  });
  const doPay = async otp => {
    if (_loading) return;
    setLoading(true);
    console.log("RIDE ID IN HANDLE CASH::::", rideId);
    try {
      await payWithCashTrigger({
        variables: {
          id: rideId,
          otp: parseInt(otp)
        }
      });
      navigate("/thankyou?id=" + rideId);
    } catch (error) {
      console.log("ERRor : ", error);
      alert("Incorrect OTP please  try again ");
      setLoading(false);
    }
  };
  const handleCashPayment = async () => {
    if (_loading) return;
    setLoading(true);
    console.log("RIDE ID IN HANDLE CASH::::", rideId);
    try {
      await payWithCashTrigger({
        variables: {
          id: rideId
        }
      });

      navigate("/thankyou?id=" + rideId);
    } catch (error) {
      console.log("ERRor : ", error);
      alert("Something went wrong");
      window.location.reload();
    }
  };
  const handleVerify = async () => {
    if (_loading) return;
    setLoading(true);
    console.log("Verify User", rideId);
    try {
      await verifyMobileTrigger({
        variables: {
          id: rideId
        }
      });
      setLoading(false);
      navigate("/payment");
    } catch (error) {
      console.log("ERRor : ", error);
      setLoading(false);
      alert("Something went wrong");
    }
  };

  const handlePaymentAyden = async selected => {
    if (_loading) return;
    let { netFare: paymentAmount } = data.ride;
    let { onlinePaymentDiscount } = data.ride.setting;
    setLoading(true);
    const config = {
      amount: paymentAmount,
      rideId: rideId,
      method: selected
    };
    const url = process.env.GATSBY_AYDEN_PAYMENT;

    try {
      let redir = await axios.post(url, config);
      setLoading(false);
      console.log("redir :",redir)
      console.log("redir.data.url",redir.data.url);
      if (typeof window !== `undefined`) {
        window.location.href = redir.data.url;
        window.location.assign(redir.data.url);
      }
    } catch (e) {
      console.log(e);
    }
    // navigate("/thankyou");
  };

  const doPayment = selected => {
    switch (selected) {
      case "cash":
        return handleCashPayment();
      default:
        return handlePaymentAyden(selected);
    }
  };

  console.log("data::::> ", data);
  const {
    netFare: paymentAmount,
    setting: { onlinePaymentDiscount }
  } = data && data.ride ? data.ride : { setting: {} };
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });

  return (
    <div className="payment-container">
      <Row className="content-wrapper">
        <Col lg={5} className="top-bar">
          {!isDesktopOrLaptop && (
            <div className="top-heading">{paymentHeading}</div>
          )}
        </Col>
        <Col
          lg={12}
          className="method-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Methods
            onPay={doPayment}
            paymentAmount={paymentAmount}
            discount={onlinePaymentDiscount}
            loading={_loading}
            verifyOTP={handleVerify}
            onPayC={doPay}
          />
        </Col>
      </Row>
    </div>
  );
};

export { PaymentComponent };
